import {reactive, toRefs, computed,defineComponent,onBeforeMount,onMounted,getCurrentInstance,nextTick} from 'vue';
import MenuCardUtil,{IMenuCardDataObj} from './menuCardUtil';
export default defineComponent({
    name:'MenuCard',
    title:'菜单',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IMenuCardDataObj=reactive<IMenuCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/menu')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                caption: [utils.UtilPub.commonValidRule(proxy.$t('menu.form.caption_rule'))],
                // path: [utils.UtilPub.commonValidRule(proxy.$t('menu.form.path_rule'))],
            },
            otherParams:{}
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new MenuCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'show'==params.comboId){
                    return [{value:0,label:'是'},{value:1,label:'否'}]
                }
                if(params && 'prop'==params.comboId){
                    return [{value:0,label:'站内打开'},{value:1,label:'站外打开'}]
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:string,selectId:string)=>{
            if('show'==selectId){}
            if('prop'==selectId){}
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{

        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,beforeOpen,beforeSaveHandler
         
        }
    }
});